.tip {
    font-size: 13px;
    background-color: var(--panel-background-color);
    opacity: 0.85;
    border: 1px solid var(--border-color);
    border-radius: 4px;
    padding: 1px 4px;
    margin: 2px 0px;
}

.info {
    color: black;
}

.warn {
    color: yellow;
}

.error {
    color: red;
}
