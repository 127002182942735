.wrapper {
    position: fixed;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
    z-index: 99999;
    background-color: rgba(0, 0, 0, 0.5);
}

.loading_text {
    color: #fff;
    font-size: 18px;
}

.progress_container {
    width: 300px;
    height: 24px;
    background-color: #f3f3f3;
    border: 1px solid #ccc;
    border-radius: 4px;
    overflow: hidden;
}

.progress_bar {
    width: 0;
    height: 100%;
    background-color: #4caf50;
    text-align: center;
    line-height: 20px;
    color: white;
    transition: all 0.5s;
}
