.modal {
  position: absolute;
  background-color: rgba(0, 0, 0, 0.4);
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 99;

  & > .content {
    padding: 16px;
    border-radius: 12px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
    background-color: #ffffff;
    width: fit-content;
    position: relative;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);

    & .header {
      display: flex;
      justify-content: end;
      margin: 0 5px 10px 0;
      & .satrt_ai_btn {
        background-color: #1677ff;
        color: #fff;
        font-size: 14px;
        padding: 8px;
        border-radius: 6px;
        border: none;
        cursor: pointer;
        margin-right: 26px;
      }
      & .off {
        cursor: pointer;
      }
    }

    & .table_box {
      width: fit-content;
      display: flex;
      justify-content: space-between;
      & table {
        margin: 6px;
        border-collapse: collapse;
        text-align: center;
        & td {
          font-size: 16px;
        }
  
        & input {
          width: 100%;
          /* height: 100%; */
          line-height: 22px;
          box-sizing: border-box;
          border: none;
          outline: none;
          text-align: center;
          font-size: 14px;
        }
      }
    }
  }
}
