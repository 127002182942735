.rootPanel {
    display: flex;
    flex-direction: column;
    border: 1px solid var(--border-color);
    border-radius: 6px;
}

.headerPanel {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 4px 6px;
    background-color: var(--border-color);
}

.headerPanel:hover {
    background-color: var(--hover-background-color);
}

.contextPanel {
    display: flex;
    flex-direction: column;
    padding: 8px 16px;
}

.expanderIcon {
    width: 12px;
    height: 12px;
    margin-right: 12px;
}

.headerText {
    font-size: 14px;
}

.hidden {
    display: none;
}
