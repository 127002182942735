.panel {
    font-size: 13px;
    background-color: var(--panel-background-color);
    opacity: 0.85;
    display: flex;
    flex-direction: column;
    border-radius: 4px;
    margin: 2px 0px;
}

.error {
    color: red;
    font-size: 11px;
}
